.swiper-pagination-bullet-active {
    background: #003C84 !important;
}

.swiper-pagination-bullet{
    background: #CCCCCC;
    opacity: 1;
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -4px;
}