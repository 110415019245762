html {
  /* this makes sure the padding and the border is included in the box sizing */
  box-sizing: border-box;
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


div {
  box-sizing: border-box;
}

body,button,a,input,h3,textarea {
  font-size: 16px;
  font-weight: 500;
}

p,h3{
  margin: 0;
}

ul{
  list-style: none;
  margin: 0;
  padding: 0;
}


.ContactUsPageContainer{
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #f0f0f0;
  box-sizing: border-box;
  padding: 16px 12px 12px 12px;
}
.ContactUsPageWrap{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.ContactUsCard{
  border-radius: 8px;
  background-color: #ffffff;
  width: 100%;
  padding: 12rem;
  box-shadow: 0px 4px 4px 0px #00000026;
  margin-bottom: 16rem;
}
@media (min-width: 640px) {
  .ContactUsCard{
    margin-bottom: 16px;
    padding: 12px;
    height: 155px;
  }
  .ContactUsPageWrap{
    max-width: 834px;
  }
}
.FAQCardContainer{
  position: relative;

}
